<template>
    <div class="database">
        <div class="search-box">
            <div class="search-one">
                <p class="left">
                    <span>查询</span>

                    <el-input
                        size="small"
                        style="width: 150px"
                        placeholder="公司、域名、电话"
                        v-model.trim="searchVal.name"
                        clearable
                    >
                    </el-input>
                    <el-button
                        @click="getData()"
                        type="primary"
                        size="small"
                        icon="el-icon-search"
                    ></el-button>
                    <span>资料状态</span>
                    <el-radio-group v-model="searchVal.statusVal" size="small">
                        <el-radio-button
                            label="未联系"
                            @click.native.prevent="clickStatus('未联系')"
                        ></el-radio-button>
                        <el-radio-button
                            label="已联系"
                            @click.native.prevent="clickStatus('已联系')"
                        ></el-radio-button>
                        <el-radio-button
                            label="已转化"
                            @click.native.prevent="clickStatus('已转化')"
                        ></el-radio-button>
                    </el-radio-group>
                    <span v-if="btnP.dbSearchFollow && !btnP.depSearch"
                        >归属人</span
                    >
                    <el-select
                        v-if="btnP.dbSearchFollow && !btnP.depSearch"
                        v-model="searchVal.followAdminId"
                        size="small"
                        style="width: 180px"
                        clearable
                        placeholder="请选择"
                        @change="getData()"
                    >
                        <el-option
                            v-for="item in userName"
                            :key="item.id"
                            :label="item.nickName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                    <!-- v-if="btnP.dbSearchConfig && !btnP.depSearch" -->
                    <span v-if="btnP.dbSearchConfig && !btnP.depSearch"
                        >人员配置归属人</span
                    >
                    <el-select
                        v-if="btnP.dbSearchConfig && !btnP.depSearch"
                        v-model="searchVal.followAdminId"
                        size="small"
                        style="width: 180px"
                        clearable
                        placeholder="请选择"
                        @change="getData()"
                    >
                        <el-option
                            v-for="item in userConfigName"
                            :key="item.shareAdminId"
                            :label="item.shareAdminName"
                            :value="item.shareAdminId"
                        >
                        </el-option>
                    </el-select>
                </p>
                <p>
                    <el-button
                        class="right"
                        type="primary"
                        style="margin-left: 29px"
                        size="small"
                        @click="getData()"
                        >查询</el-button
                    >
                    <el-button
                        class="right"
                        @click="searchEmpty()"
                        type="primary"
                        style="
                            margin: 0 16px 0 0;
                            background: none;
                            border-color: #d9d9d9;
                            color: #333333;
                        "
                        icon="el-icon-refresh-left"
                        size="small"
                        >重置</el-button
                    >
                </p>
            </div>
            <div class="search-two">
                <div class="left">
                    <!-- <span v-if="btnP.depSearch">部门</span>
                    <el-select
                        v-model="searchVal.departmentId"
                        v-if="btnP.depSearch"
                        size="small"
                        filterable
                        style="width: 195px"
                        clearable
                        placeholder="请选择"
                        @change="changeDepartmentId"
                    >
                        <el-option
                            v-for="(item, index) in departmentList"
                            v-if="index > 0"
                            :key="item.id"
                            :label="item.departmentName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select> -->
                    <Department
                        ref="department"
                        :departmentWidth="195"
                        :adminWidth="175"
                        :depSearch="btnP.depSearch"
                        :searchUser="btnP.depSearch"
                        @searchData="searchData"
                    ></Department>

                    <span>选择标签</span>
                    <el-button
                        @click="onChoiceLabel"
                        type="primary"
                        size="small"
                        style="
                            border-color: #e5e5e5;
                            background: none;
                            color: #333333;
                        "
                        >请选择
                        <i
                            class="el-icon-arrow-right"
                            style="color: #2162cb"
                        ></i>
                    </el-button>
                    <div class="labelList">
                        <p
                            :class="item.category == 0 ? 'blue' : 'yellow'"
                            v-for="item in $searchLabel.state.labelList"
                            :key="item.id"
                        >
                            {{ item.tagName }}
                            <span class="cancels" @click="onDeleteLabel(item)">
                                <span class="el-icon-close"></span>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="table-title">
            <p class="status_btn">
                <span
                    @click="(pagesize = 20), (currentPage = 1), getTableData(2)"
                    :class="active == 2 ? 'isStatusColor' : ''"
                    >全部</span
                >
                <span
                    @click="(pagesize = 20), (currentPage = 1), getTableData(1)"
                    :class="active == 1 ? 'isStatusColor' : ''"
                >
                    星标
                </span>
                <span
                    @click="(pagesize = 20), (currentPage = 1), getTableData(0)"
                    :class="active == 0 ? 'isStatusColor' : ''"
                    >非星标</span
                >
            </p>
            <span>
                <button
                    @click="onBatchDelete()"
                    v-if="searchVal.statusVal != '已转化' && btnP.dbDelete"
                >
                    <i
                        class="iconfont icon-fenpei1"
                        style="font-size: 10px"
                    ></i>
                    批量删除
                </button>
                <button
                    @click="onDistribution()"
                    v-if="searchVal.statusVal != '已转化' && btnP.distribution"
                >
                    <i
                        class="iconfont icon-fenpei1"
                        style="font-size: 10px"
                    ></i>
                    分配资料
                </button>
                <button
                    @click="onMerger()"
                    v-if="searchVal.statusVal != '已转化' && btnP.dbMerger"
                >
                    <i class="iconfont icon-hebing" style="font-size: 10px"></i>
                    合并资料
                </button>
                <button @click="onImport()" v-if="btnP.dbImport">
                    <i class="el-icon-refresh"></i> 导入资料
                </button>
                <button @click="onAdd()" v-if="btnP.dbAdd">
                    <i class="el-icon-plus"></i> 新增资料
                </button>
                <button
                    @click="onClean()"
                    v-if="searchVal.statusVal != '已转化'"
                >
                    <i class="iconfont icon-qingxi" style="font-size: 12px"></i>
                    清洗资料
                </button>
            </span>
        </div>
        <div class="table">
            <Table
                ref="table"
                @totalNub="totalNub"
                @addCustomer="addCustomerDialog"
                @delData="showDelDialog"
                @onDealings="onDealings"
                @onStarMark="onStarMark"
                @onEdit="showEditDrawer"
                @onDetails="onDetails"
                @onClue="onClue"
            />
        </div>
        <div class="page-box">
            <el-pagination
                v-if="isPage"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50, 500]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <!-- 新增资料 -->
        <el-drawer
            :visible.sync="drawerAdd"
            :direction="direction"
            :before-close="handleClose"
            size="480px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    新增资料
                </div>
            </template>
            <AddData @close="handleClose" ref="details" />
        </el-drawer>
        <!-- 导入 -->
        <el-dialog
            :visible.sync="dialogImport"
            width="560px"
            :before-close="handleImport"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    导入资料
                </div>
            </template>
            <Import ref="import" @close="handleImport" />
        </el-dialog>
        <!-- 清洗 -->
        <el-dialog
            :visible.sync="drawerClean"
            width="560px"
            :before-close="handleClean"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    清洗资料
                </div>
            </template>
            <Clean @close="handleClean" ref="clean" />
        </el-dialog>
        <!-- 往来记录 -->
        <el-drawer
            :visible.sync="drawerDealings"
            :direction="direction"
            :before-close="handleClose"
            size="880px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    往来记录
                </div>
            </template>
            <Dealings
                v-if="drawerDealings"
                @getDealingList="getDealingList"
                @close="handleClose"
                @dealingAdd="dealingAdd"
                @addExplain="addExplain"
                ref="dealings"
            />
        </el-drawer>
        <el-drawer
            :visible.sync="drawerClue"
            :direction="direction"
            :before-close="handleClose"
            size="880px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    创建线索
                </div>
            </template>
            <DirectAddClue @close="handleClose" ref="directAddClue" />
        </el-drawer>
        <!-- 合并资料 -->
        <el-drawer
            :visible.sync="drawerMerger"
            :direction="direction"
            :before-close="handleMerger"
            size="910px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    合并资料
                </div>
            </template>
            <Merger @close="handleMerger" ref="merger" />
        </el-drawer>
        <!-- 分配资料 -->
        <el-dialog
            :visible.sync="drawerDistribution"
            :before-close="handleClose"
            width="560px"
            class="dialog_add_customer"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    分配资料
                </div>
            </template>
            <div class="dialog_add_customer_body">
                <p style="color: #333">
                    已选择
                    {{ choiceDataLength }} 条资料，请选择需要分配的负责人：
                </p>
                <el-form
                    label-position="left"
                    size="small"
                    style="margin-top: 10px"
                >
                    <el-form-item label="负责人" required>
                        <el-select
                            placeholder="请选择或输入搜索负责人"
                            style="width: 240px"
                            v-model="distribution.adminId"
                            filterable
                        >
                            <el-option
                                v-for="item in distributionMemberData"
                                :key="item.id"
                                :label="item.nickName"
                                :value="item.id"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog_footer">
                <el-button @click="handleClose" round size="mini"
                    >取 消</el-button
                >
                <el-button
                    type="primary"
                    @click="onSubmitDistribution"
                    round
                    size="mini"
                    >保 存</el-button
                >
            </div>
        </el-dialog>
        <!-- 删除 -->
        <el-dialog
            :visible.sync="dialogDelete"
            width="560px"
            :before-close="handleClose"
            append-to-body
            class="dialog_del"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    删除
                </div>
            </template>
            <p style="width: 80%; margin: 20px auto 180px">
                已选择1条资料，删除后不可恢复，是否确认删除？
            </p>
            <div slot="footer" class="dialog_footer">
                <el-button @click="dialogDelete = false" round size="mini"
                    >取 消</el-button
                >
                <el-button type="primary" @click="delData" round size="mini"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
        <!-- 编辑 or  详情 -->
        <el-drawer
            :visible.sync="drawerEdit"
            :direction="direction"
            :before-close="handleClose"
            size="880px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    资料详情
                </div>
            </template>
            <Detail
                @close="handleClose"
                @delData="showDelDialog"
                ref="detail"
            />
        </el-drawer>
        <!-- 添加客户-有客户 -->
        <el-dialog
            :visible.sync="dialogRelationCustomer"
            width="560px"
            :before-close="handleClose"
            append-to-body
            class="dialog_add_customer"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    客户
                </div>
            </template>
            <div class="dialog_add_customer_body">
                <p>
                    检测到该资料联系方式在当前品牌客户中已存在，是否将该资料关联至客户：
                </p>
                <el-form label-position="left" size="small">
                    <el-form-item>
                        <el-select
                            placeholder="请选择"
                            style="width: 100%; margin-top: 10px"
                            v-model="customerDO.id"
                        >
                            <el-option
                                v-for="item in relationCustomerList"
                                :key="item.id"
                                :label="
                                    item.companyName + '-' + item.createTime
                                "
                                :value="item.id"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog_footer">
                <el-button @click="unRelationAdd" round size="mini"
                    >不关联并新建</el-button
                >
                <el-button
                    type="primary"
                    @click="relationCustomer"
                    round
                    size="mini"
                    >关 联</el-button
                >
            </div>
        </el-dialog>
        <!-- 保存并新建线索 -->
        <el-drawer
            :visible.sync="drawerAddClue"
            :direction="direction"
            :before-close="handleClose"
            size="560px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    客户新增线索
                </div>
            </template>
            <AddClue
                @close="handleClose"
                @onSubmit="onSubmitClue"
                ref="addClue"
            />
        </el-drawer>

        <!-- 标签搜索 -->
        <el-dialog
            :visible.sync="dialogChoice"
            width="560px"
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    选择标签
                </div>
            </template>
            <ChoiceLabel @close="handleClose" :isGet="isGet" />
        </el-dialog>
    </div>
</template>
<script>
import {
    setStatus,
    relationCustomer,
    dealingList,
    dealingAdd,
    dealingDesAdd,
    libraryGet,
    libraryAllot,
    libraryBatchDel,
} from '@/api/database/database';
import { configList } from '@/api/configure/configure.js';
import { roleList, customerList } from '@/api/customer/customer.js';
import { addClue as customerAddClue } from '@/api/user/clue.js';
import { departmentList } from '@/api/admin/framework/framework.js';
import { customerAddMy } from '@/api/user/ae/customer.js';
import { getCustomerId, getCustomerAdmin } from '@/api/user/ae/customer.js';
import Table from '../table/table.vue';
import AddData from '../addData/addData.vue';
import Import from '../import/import.vue';
import AddCustomer from '../addCustomer/addCustomer.vue';
import Clean from '../clean/clean.vue';
import Dealings from '../dealings/dealings.vue';
import Merger from '../merger/merger.vue';
import Detail from '../detail/detail.vue';
import AddClue from '../addClue/addClue.vue';
import DirectAddClue from './addClue/addClue.vue';
import ChoiceLabel from '../label/searchLabel/searchLabel.vue';

import Department from '../../../components/Department.vue';
export default {
    components: {
        Table,
        AddData,
        Import,
        AddCustomer,
        Clean,
        Merger,
        Dealings,
        Detail,
        AddClue,
        DirectAddClue,
        ChoiceLabel,
        Department,
    },
    data() {
        return {
            searchVal: {
                statusVal: '未联系',
                followAdminId: '',
                name: '',
            },
            userName: [],
            isBut: true,
            isClean: true,
            btnP: {},
            pagesize: 20,
            total: 0,
            currentPage: 1,
            drawerAdd: false,
            dialogImport: false,
            dialogChoice: false,
            dialogDelete: false,
            drawerAddCustomer: false,
            drawerClean: false,
            drawerEdit: false,
            drawerDealings: false,
            drawerClue: false,
            drawerMerger: false,
            dialogRelationCustomer: false,
            drawerDistribution: false,
            drawerAddClue: false,
            direction: 'rtl',
            tableRowId: '',
            isEdit: true,
            relationCustomerList: [],
            distributionMemberData: [],
            tableRow: {},
            customerDO: {
                id: '',
            },
            userName: [],
            departmentList: [],
            userConfigName: [],
            choiceDataLength: 0,
            distribution: {},
            isPage: false,
            isGet: false,
            active: 2,
            arr: [],
        };
    },

    mounted() {
        this.getBtn();
        // this.roleList();
    },
    methods: {
        searchData(data) {
            // console.log(val);
            this.searchVal.departmentId = data.departmentId;
            this.searchVal.followAdminId = data.adminId;
            this.getData();
        },
        // 获取部门
        getDepartmentList() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    setTimeout(() => {
                        this.$refs.department.getData(this.btnP, res.data);
                    }, 0);
                    // this.departmentList = res.data;
                }
            });
        },
        // 获取按钮权限
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            this.btnP = btn;
            this.getPageData();
            this.getData();

            if (btn.depSearch) {
                this.getDepartmentList();
            } else {
                if (btn.dbSearchConfig) {
                    this.getUserConfigList();
                }
                if (btn.dbSearchFollow) {
                    this.roleList();
                }
            }
        },
        getPageData() {
            if (
                sessionStorage.getItem('pageData') &&
                JSON.parse(sessionStorage.getItem('pageData'))
            ) {
                this.currentPage = JSON.parse(
                    sessionStorage.getItem('pageData')
                ).pageNum;
                this.pagesize = JSON.parse(
                    sessionStorage.getItem('pageData')
                ).pageSize;

                if (
                    JSON.parse(sessionStorage.getItem('pageData')).param
                        .mark === 0 ||
                    JSON.parse(sessionStorage.getItem('pageData')).param.mark ==
                        1
                ) {
                    this.active = JSON.parse(
                        sessionStorage.getItem('pageData')
                    ).param.mark;
                }

                console.log(sessionStorage.getItem('pageData'));
                this.searchVal.departmentId =
                    this.$refs.department.$data.departmentId =
                        JSON.parse(sessionStorage.getItem('pageData')).param
                            .departmentId || undefined;

                if (
                    JSON.parse(sessionStorage.getItem('pageData')).param
                        .followAdminId
                ) {
                    if (!this.btnP.depSearch) {
                        if (this.btnP.dbSearchConfig) {
                            this.getUserConfigList();
                        }
                        if (this.btnP.dbSearchFollow) {
                            this.roleList();
                        }
                    } else {
                        this.$refs.department.getUserList(
                            JSON.parse(sessionStorage.getItem('pageData')).param
                                .departmentId
                        );
                    }
                }
                if (
                    JSON.parse(sessionStorage.getItem('pageData')).param.tagList
                ) {
                    this.$searchLabel.commit(
                        'getLabelList',
                        JSON.stringify(
                            JSON.parse(sessionStorage.getItem('pageData')).param
                                .tagList
                        )
                    );
                }
                this.$refs.department.$data.adminId =
                    this.searchVal.followAdminId =
                        JSON.parse(sessionStorage.getItem('pageData')).param
                            .followAdminId || '';
                this.searchVal.statusVal =
                    JSON.parse(sessionStorage.getItem('pageData')).param
                        .status == 1
                        ? '已联系'
                        : '未联系';
                this.searchVal.name =
                    JSON.parse(sessionStorage.getItem('pageData')).param.name ||
                    '';
                sessionStorage.removeItem('pageData');
                this.isPage = true;
            } else {
                this.isPage = true;
            }
        },
        // AE 获取人员配置归属人
        getUserConfigList() {
            let data = {
                param: {
                    receiveAdminId: sessionStorage.getItem('adminId'),
                },
            };
            configList(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    res.data.list.push({
                        shareAdminName: sessionStorage.getItem('nickName'),
                        shareAdminId: sessionStorage.getItem('adminId'),
                    });
                    this.userConfigName = res.data.list;
                }
            });
        },

        // 搜索获取列表
        getData() {
            let userTypr = 1;
            if (this.btnP.dbImport) {
                userTypr = 2;
            }
            let isChoice = false;
            if (!this.isBut || !this.isClean) {
                isChoice = true;
            }
            this.$refs.table.getData(
                userTypr,
                this.searchVal,
                this.currentPage,
                this.pagesize,
                this.btnP,
                isChoice,
                this.active
            );
        },
        totalNub(i) {
            this.total = i;
        },
        // 导入
        onImport() {
            this.dialogImport = true;
        },
        handleImport() {
            this.dialogImport = false;
            this.getData();
        },
        // 获取归属人列表
        roleList() {
            let data = {
                param: {
                    departmentId: sessionStorage.getItem('departmentId'),
                    roleTypeList: [2, 3, 4, 8, 9, 10],
                },
            };
            roleList(data).then((res) => {
                // // console.log(res);
                if (res.code == 200) {
                    this.userName = res.data;
                }
            });
        },
        // 获取分配负责人
        distributionMember() {
            let data = {
                param: {
                    roleTypeList: [2, 3, 4, 8, 9, 10],
                },
            };
            if (this.btnP.dbSearchFollow && !this.btnP.depSearch) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
            }
            roleList(data).then((res) => {
                // // console.log(res);
                if (res.code == 200) {
                    this.distributionMemberData = res.data;
                }
            });
        },
        // 合并
        onMerger() {
            this.arr = [];
            let arr = this.$refs.table.choiceArr;

            if (arr.length <= 0) {
                return this.$message.error('请选择资料');
            } else if (arr.length == 1) {
                return this.$message.error('1条资料不能合并');
            } else if (arr.length > 3) {
                return this.$message.error('最多选择3条资料');
            }
            this.drawerMerger = true;
            arr.forEach((item) => {
                this.libraryGet(item.id);
            });
            setTimeout(() => {
                this.arr.forEach((item) => {
                    item.tag = false;
                });
                this.$refs.merger.getData(this.arr);
            }, 0);
        },
        // 批量删除
        onBatchDelete() {
            this.arr = [];
            let arr = this.$refs.table.choiceArr;
            if (arr.length <= 0) {
                return this.$message.error('请选择资料');
            }
            this.$confirm('已选资料删除后不可恢复，是否确认删除？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let data = {
                        param: arr.map((item) => {
                            return {
                                id: item.id,
                            };
                        }),
                    };
                    libraryBatchDel(data).then((res) => {
                        if (res.code == 200) {
                            this.$message.success('操作成功');
                            this.getData();
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    });
                });
        },
        // 分配
        onDistribution() {
            this.arr = [];
            let arr = this.$refs.table.choiceArr;
            if (arr.length <= 0) {
                return this.$message.error('请选择资料');
            }
            this.choiceDataLength = arr.length;
            this.drawerDistribution = true;
            this.distributionMember();
        },
        onSubmitDistribution() {
            if (!this.distribution.adminId) {
                return this.$message.error('请选择负责人');
            }
            let libraryDOList = this.$refs.table.choiceArr.map((item) => {
                return {
                    id: item.id,
                };
            });
            console.log(libraryDOList);
            let data = {
                param: {
                    adminId: this.distribution.adminId,
                    libraryDOList,
                },
            };
            libraryAllot(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.distribution = {};
                    this.handleClose();
                }
            });
        },
        libraryGet(id) {
            let data = {
                param: {
                    id,
                },
            };
            libraryGet(data).then((res) => {
                if (res.code == 200 && res.data) {
                    this.arr.push(res.data);
                }
            });
        },
        handleMerger() {
            this.drawerMerger = false;
            this.getData();
        },
        // 新增
        onAdd() {
            this.drawerAdd = true;
        },
        // 清洗资料
        onClean() {
            let arr = this.$refs.table.choiceArr;

            if (arr.length <= 0) {
                return this.$message.error('请选择资料');
            }

            this.drawerClean = true;
            setTimeout(() => {
                this.$refs.clean.getData(arr);
            }, 0);
        },
        handleClean() {
            this.drawerClean = false;
            this.isClean = false;
            this.getData();
        },
        // 添加客户弹窗
        addCustomerDialog(row) {
            this.tableRow = row;
            let data = {
                pageSize: 0,
                pageNum: 0,
                param: {
                    companyName: row.companyName,
                    enterpriseType: 1,
                },
            };
            getCustomerAdmin(data).then((res) => {
                if (res.code == 200) {
                    this.$router.push({
                        path: '/addDataBaseCus',
                        query: {
                            id: row.id,
                        },
                    });
                }
            });
        },
        // 不关联并新建
        unRelationAdd() {
            this.dialogRelationCustomer = false;

            this.$router.push({
                path: '/addDataBaseCus',
                query: {
                    id: this.tableRow.id,
                },
            });
        },
        // 客户
        relationCustomer() {
            let data = {
                param: {
                    customerDO: { id: this.customerDO.id },
                    libraryDO: { id: this.tableRow.id },
                },
            };
            relationCustomer(data).then((res) => {
                if (res.code == 200) {
                    let data2 = {
                        param: {
                            companyName: this.tableRow.companyName,
                            adminId: sessionStorage.getItem('adminId'),
                        },
                    };
                    getCustomerAdmin(data2).then((res) => {
                        if (res.code == 200) {
                            if (res.data.total > 0) {
                                this.$message.success('操作成功');
                                this.handleClose();
                            } else {
                                let datas = {
                                    param: {
                                        id: this.customerDO.id,
                                    },
                                };
                                customerAddMy(datas).then((ress) => {
                                    if (ress.code == 200) {
                                        this.$message.success('操作成功');
                                        this.handleClose();
                                    }
                                });
                            }
                        }
                    });
                }
            });
        },
        // 创建线索弹窗
        onClue(row) {
            this.tableRow = row;
            this.drawerClue = true;
            setTimeout(() => {
                this.$refs.directAddClue.getData(row);
            }, 0);
        },
        clickStatus(e) {
            this.searchVal.statusVal = e;
            this.getData();
        },
        showDelDialog(data) {
            this.dialogDelete = true;
            this.tableRowId = data.id;
        },
        // 删除资料
        delData() {
            let data = {
                param: {
                    id: this.tableRowId,
                    status: 3,
                },
            };
            // console.log(data);
            setStatus(data).then((res) => {
                if (res.code == 200 && res.data) {
                    this.$message.success('操作成功');
                    this.handleClose();
                }
            });
        },
        // 设置星标
        onStarMark(row) {
            let mark = row.mark == 0 ? 1 : 0;
            let data = {
                param: {
                    id: row.id,
                    mark: mark,
                },
            };
            setStatus(data).then((res) => {
                if (res.code == 200 && res.data) {
                    this.$message.success('操作成功');
                    this.handleClose();
                }
            });
        },
        getTableData(active) {
            this.active = active;
            this.getData();
        },
        // 资料库往来
        onDealings(row) {
            this.drawerDealings = true;
            this.tableRowId = row.id;
            setTimeout(() => {
                this.$refs.dealings.$data.data = row;
                this.$refs.dealings.$data.isLibrary = true;
            }, 0);
            this.getDealingList(row);
        },
        // 往来添加说明
        addExplain(data) {
            dealingDesAdd(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('添加成功');
                    this.getDealingList({ id: this.tableRowId });
                }
            });
        },
        // 往来添加
        dealingAdd(data) {
            data.param.libraryId = this.$refs.dealings.$data.data.id;
            dealingAdd(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.getDealingList(this.$refs.dealings.$data.data);
                }
            });
        },
        getDealingList(row) {
            let data = {
                param: {
                    libraryId: row.id,
                },
            };
            dealingList(data).then((res) => {
                if (res.code == 200) {
                    this.$refs.dealings.$data.historyData = res.data.list;
                }
            });
        },
        // 编辑
        // 0 未清洗  1 清洗失败  2 清洗成功
        showEditDrawer(data) {
            // this.drawerEdit = true;
            this.$router.push({
                path: '/dataBasedetails',
                query: {
                    id: data.id,
                },
            });
        },
        // 详情
        onDetails(data) {
            let num = 0,
                arr = [];
            if (data.libraryDomainBOList.length > 0) {
                data.libraryDomainBOList.forEach((item) => {
                    if (item.enterpriseDomainDO) {
                        arr.push(item.enterpriseDomainDO.icpStatus);
                        arr.push(item.enterpriseDomainDO.whoisStatus);
                    }
                });
            }
            if (data.enterpriseNameDO) {
                arr.push(data.enterpriseNameDO.sponsorUnitStatus);
            }
            arr.forEach((item) => {
                if (item == 1) {
                    num++;
                }
            });
            this.drawerEdit = true;
            setTimeout(() => {
                this.$refs.detail.$data.data = data;
                this.$refs.detail.$data.disabled = true;

                this.$refs.detail.$data.wash =
                    num == 0 ? 0 : num == arr.length ? 2 : 1;
            }, 0);
        },
        // 保存并新建线索弹窗
        addClueDrawer(row) {
            this.tableRowId = row.id;
            let data = {
                param: {
                    id: row.id,
                },
            };
            getCustomerId(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.drawerAddCustomer = false;
                    this.drawerAddClue = true;
                    setTimeout(() => {
                        this.$refs.addClue.$data.customerDO = res.data;
                        // this.$refs.addClue.customerDealings(row.id);
                    }, 0);
                }
            });
        },
        // 生成线索
        onSubmitClue(data) {
            data.param.libraryId = this.tableRowId;
            data.param.lineDO.sourceCategory = 2;
            customerAddClue(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.handleClose();
                }
            });
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getData();
        },
        onChoiceLabel() {
            this.dialogChoice = true;
            setTimeout(() => {
                this.isGet = !this.isGet;
            }, 100);
        },
        // 移除标签
        onDeleteLabel(item) {
            this.$searchLabel.commit('removeLabel', JSON.stringify(item));
            this.getData();
        },
        // 关闭抽屉
        handleClose() {
            this.drawerAdd = false;
            this.drawerAddCustomer = false;
            this.drawerDealings = false;
            this.drawerClue = false;
            this.dialogDelete = false;
            this.drawerEdit = false;
            this.dialogRelationCustomer = false;
            this.drawerAddClue = false;
            this.dialogChoice = false;
            this.drawerDistribution = false;
            this.getData();
            this.tableRow = {};
            this.$choiceLabel.commit('emptyData');
        },
        // 重置
        searchEmpty() {
            this.searchVal = {
                statusVal: '未联系',
                name: '',
                followAdminId: '',
            };
            this.active = 2;
            this.currentPage = 1;
            this.getData();
        },
    },
    destroyed() {
        this.$searchLabel.commit('emptyData');
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.database {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .search-box {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        span {
            font-size: 12px;
            font-weight: 600;
            color: #000;
            margin: 0 16px;
        }
        .search-one {
            padding: 10px 0 12px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .left {
                button {
                    background: #2370eb;
                    border-radius: 2px;
                    border-color: #2370eb;
                    margin-right: 16px;
                }
            }
            .right {
                background: #2370eb;
                border-radius: 2px;
                border-color: #2370eb;
                margin-right: 16px;
            }
        }
    }
    .table-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 16px;
        .status_btn {
            span {
                display: inline-block;
                border: 1px solid #f0f0f0;
                border-radius: 13px;
                padding: 2px 20px;
                font-size: 13px;
                color: #666666;
                margin-right: 12px;
                cursor: pointer;
                position: relative;
            }
            .isStatusColor {
                background: #e9f2ff;
                color: #2370eb;
                border-color: #e9f2ff;
            }
        }
        .add {
            border-color: #d9d9d9;
            color: #333333;
        }
        p {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
        }
        button {
            font-size: 12px;
            padding: 0 7px 0 12px;
            height: 24px;
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid #2370eb;
            cursor: pointer;
            color: #2370eb;
            margin-left: 16px;
        }

        button:hover {
            background: #2370eb;
            color: #ffffff;
            border-color: #2370eb;
        }
        .cancel:hover {
            background: #fff;
            color: #666;
            border-color: #e5e5e5;
        }
    }
    .table {
        flex: 1;
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dialog_del {
        .el-dialog__footer {
            padding: 0;
        }
    }
}
.dialog_add_customer {
    .el-dialog__footer {
        padding: 0;
    }
    .dialog_add_customer_body {
        width: 65%;
        margin: 60px auto 150px;
    }
}
.search-two {
    margin-bottom: 10px;
}
.labelList {
    display: inline-block;
    .cancels {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        color: #fff;
        border-top: 10px solid rgba(255, 255, 255, 0);
        border-right: 10px solid rgba(255, 255, 255, 0);
        border-bottom: 10px solid rgba(255, 255, 255, 0);
        border-left: 10px solid yellow;
        -webkit-transform: rotate(45deg); /* for Chrome || Safari */
        -moz-transform: rotate(45deg); /* for Firefox */
        -ms-transform: rotate(45deg); /* for IE */
        -o-transform: rotate(45deg);
        right: -26px;
        bottom: -10px;
        .el-icon-close {
            color: #fff;
            position: absolute;
            font-size: 8px;
            left: -28px;
            top: -6px;
            -webkit-transform: rotate(45deg); /* for Chrome || Safari */
            -moz-transform: rotate(45deg); /* for Firefox */
            -ms-transform: rotate(45deg); /* for IE */
            -o-transform: rotate(45deg);
        }
    }
    p {
        position: relative;
        display: inline-block;
        padding: 0 10px;
        padding-bottom: 0px !important;
        line-height: 30px;
        border-radius: 2px;
        border: 1px solid #2370eb;
        margin-left: 16px;
        text-align: center;
        font-size: 14px;
    }
    .blue {
        color: #2370eb;
        border-color: #2370eb;
        .cancels {
            border-left: 10px solid #2370eb;
        }
    }
    .yellow {
        color: #fa8c15;
        border-color: #fa8c15;
        .cancels {
            border-left: 10px solid #fa8c15;
        }
    }
}
</style>
