<template>
    <div class="add_cutomer_box">
        <div class="add_customer">
            <div class="left_form">
                <p class="wait_data"><span>待添加资料</span></p>
                <WaitData :formData="formData"></WaitData>
                <p class="business_info">
                    <span>工商信息</span>
                </p>
                <BusinessInfo
                    :businessInfo="formData.enterpriseNameDO"
                ></BusinessInfo>
            </div>
            <div class="right_form">
                <ClueForm ref="clueForm"></ClueForm>
                <!-- @inputCompanyName="inputCompanyName" -->
            </div>
        </div>
        <div class="footer_btn">
            <el-button size="mini" round @click="onClose">取消</el-button>
            <el-button type="primary" size="mini" round @click="onSubmit"
                >保存</el-button
            >
        </div>
    </div>
</template>

<script>
import { libraryGet } from '@/api/database/database';
import { clueAdd } from '@/api/clue/meClue';
import WaitData from './components/waitData.vue';
import BusinessInfo from './components/businessInfo.vue';
import ClueForm from './components/clueForm.vue';

export default {
    name: '',
    props: {},
    data() {
        return {
            formData: {},
            tag: true,
            active: true,
            customerDO: {},
            customerContactList: [],
            num: 0,
            customer: {},
        };
    },
    components: {
        WaitData,
        BusinessInfo,
        ClueForm,
    },
    created() {},
    methods: {
        getData(row) {
            sessionStorage.setItem('customerlist', 1);
            // this.$refs.contactsForm.$data.customerContactList = [];
            // this.$refs.customerForm.$data.customerTagList = [];
            // this.$refs.customerForm.$data.customerDomainList = [];
            // this.$refs.customerForm.$data.address = [];
            // this.$refs.customerForm.$data.customerDO = {
            //     companyName: '',
            //     companyDomain: '',
            //     customerMainContactList: [],
            //     number: '',
            //     remark: '',
            //     primaryEmailBrand: '',
            //     contactRemark: '',
            // };
            // this.$refs.contactsForm.$data.
            this.libraryGet(row);
        },
        // 修改工商信息展示状态
        changeStatus() {
            this.tag = !this.tag;
        },
        // 变更基础信息和联系人
        changeComp(bool) {
            this.active = bool;
        },
        libraryGet(row) {
            let data = {
                param: {
                    id: row.id,
                },
            };
            libraryGet(data).then((res) => {
                if (res.code == 200) {
                    this.formData = res.data;
                    this.$refs.clueForm.getData(res.data);
                }
            });
        },

        // 创建线索
        onSubmit() {
            let clue = this.$refs.clueForm.form;
            console.log(clue);
            if (!clue.productClue) {
                return this.$message.error('请选择线索产品');
            } else if (!clue.remark) {
                return this.$message.error('请输入需求');
            }
            // if (!this.$refs.clueForm.$data.tag) {
            //     return this.$message.error('公司名称重复');
            // }
            let data = {
                param: clue,
            };
            data.param.lineTagList = this.$choiceLabel.state.labelList;
            data.param.provinceId = this.$refs.clueForm.addressArr[0];
            data.param.cityId = this.$refs.clueForm.addressArr[1];
            data.param.lineIntercourseList =
                this.$refs.clueForm.intercourseList;
            clueAdd(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('添加成功');
                    this.onClose();
                }
            });
        },
        onClose() {
            sessionStorage.removeItem('customerlist');
            this.$emit('close');
        },
    },
};
</script>

<style scoped>
.add_cutomer_box {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
}
.add_customer {
    background-color: #f0f2f4;
    display: flex;
    width: 100%;
    flex: 1;
    justify-content: space-between;
    overflow: hidden;
}

.add_customer > div {
    width: 49.5%;
    background-color: #fff;
    box-sizing: border-box;
    overflow-y: auto;
}
.add_customer > div p {
    font-size: 16px;
}
.wait_data {
    height: 50px;

    line-height: 50px;
    background: #fff;
    border-bottom: 1px solid #e5e5e5;
    font-size: 14px !important;
    font-weight: 600;
    padding-left: 20px;
    position: relative;
}
.wait_data:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 16px;
    background-color: #2370eb;
}
.customer_form {
    padding: 0 30px 5px 30px;
    border-bottom: 1px solid #eeeeee;
}
.customer_form span {
    border-radius: 1px;
    display: inline-block;
    color: #fa8c15;
    font-weight: 600;
    line-height: 1.4;
}
.customer_form div {
    float: right;
    font-size: 14px;
}
.customer_form div span {
    color: #2370eb;
    position: relative;
    cursor: pointer;
}
.customer_form div span:first-child {
    margin-right: 50px;
}
.el-button--primary {
    background-color: #2370eb;
    border-color: #2370eb;
}
.customer_form .active:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 2px;
    background-color: #2370eb;
    bottom: -7px;
    left: 50%;
    transform: translateX(-50%);
}
.add_customer .business_info {
    margin-top: 25px;
    position: relative;
    margin-bottom: 10px;
    position: relative;
    font-size: 14px;
    font-weight: 600;
    color: #333;
    padding-left: 35px;
}
.add_customer .business_info:before {
    content: '';
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    height: 16px;
    width: 2px;
    background: #2370eb;
}
.add_customer .business_info .hide_btn {
    float: right;
    border-radius: 1px;
    border: 1px solid #2370eb;
    line-height: 1.5;
    font-size: 14px;
    padding: 0 8px;
    /* display: inline-block; */
    color: #2370eb;
    cursor: pointer;
}
.add_cutomer_box .footer_btn {
    height: 50px;
    line-height: 50px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
    padding-right: 30px;
}
.footer_btn button:nth-child(2) {
    margin: 0 10px 0 20px;
}
</style>
