var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"waitData"},[_c('el-form',{attrs:{"label-width":"80px","label-position":"left","size":"mini"}},[_c('el-form-item',{attrs:{"label":"公司名称"}},[_c('div',{staticClass:"info"},[_vm._v(_vm._s(_vm.waitData.companyName || '--'))])]),_c('el-form-item',{attrs:{"label":"客户类型"}},[_c('div',{staticClass:"info"},[_vm._v(" "+_vm._s(_vm.waitData.companyType == 1 ? '企业' : _vm.waitData.companyType == 2 ? '个人' : '- -')+" ")])]),_vm._l((_vm.waitData.libraryDomainBOList),function(item){return [_c('div',{key:item.id},[_c('el-form-item',{attrs:{"label":"域名"}},[_c('div',{staticClass:"info"},[_vm._v(_vm._s(item.domain))])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.tag),expression:"item.tag"}],staticClass:"domain_detail"},[_c('div',{staticClass:"domain_info"},[_c('el-form-item',{attrs:{"label":"原邮箱品牌"}},[(item.enterpriseDomainDO)?_c('div',{staticClass:"info"},[(
                                        _vm.$tableDataHandle.dominBrand(
                                            item.enterpriseDomainDO.domainMX
                                        ) == '其他邮箱'
                                    )?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":item.enterpriseDomainDO.domainMX,"placement":"top"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$tableDataHandle.dominBrand( item.enterpriseDomainDO .domainMX ))+" ")])]):_c('span',[_vm._v(" "+_vm._s(_vm.$tableDataHandle.dominBrand( item.enterpriseDomainDO.domainMX ))+" ")])],1):_c('div',[_vm._v("- -")])]),_c('el-form-item',{attrs:{"label":"备案人"}},[(item.enterpriseDomainDO)?_c('div',{staticClass:"info"},[_vm._v(" "+_vm._s(item.enterpriseDomainDO.icpOwner || '--')+" ")]):_c('div',[_vm._v("- -")])]),_c('el-form-item',{attrs:{"label":"网站名"}},[(item.enterpriseDomainDO)?_c('div',{staticClass:"info"},[_vm._v(" "+_vm._s(item.enterpriseDomainDO.icpRecordName || '--')+" ")]):_c('div',[_vm._v("- -")])]),_c('el-form-item',{attrs:{"label":"whois"}},[(item.enterpriseDomainDO)?_c('div',{staticClass:"info"},[_vm._v(" "+_vm._s(item.enterpriseDomainDO .icpDomainRecordTime || '--')+" "),_c('i',{staticClass:"el-icon-right"}),_vm._v(" "+_vm._s(item.enterpriseDomainDO.whoisDueDate ? item.enterpriseDomainDO.whoisDueDate.split( ' ' )[0] : '--')+" ")]):_c('div',[_vm._v("- -")])]),_c('el-form-item',{staticStyle:{"margin-bottom":"0"}},[_c('span',{staticClass:"mini_btn",on:{"click":function($event){return _vm.hideDetail(item.id)}}},[_vm._v("收起 "),_c('i',{staticClass:"el-icon-arrow-up"})])])],1)]),_c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(!item.tag),expression:"!item.tag"}],staticStyle:{"padding-left":"10px"}},[_c('span',{staticClass:"mini_btn",on:{"click":function($event){return _vm.showDetail(item.id)}}},[_vm._v("详细信息 "),_c('i',{staticClass:"el-icon-arrow-down"})])])],1)]}),_c('el-form-item',{attrs:{"label":"联系方式"}},[(
                    _vm.waitData.libraryContactBOList &&
                    _vm.waitData.libraryContactBOList.length > 0
                )?_c('div',_vm._l((_vm.waitData.libraryContactBOList),function(item){return _c('div',{key:item.id,staticClass:"info contact_way_box"},[_c('span',{staticClass:"contact_way"},[_vm._v(_vm._s(_vm.$tableDataHandle.contactWay(item)))]),_c('span',{staticClass:"contact_way"},[_vm._v(_vm._s(item.contactContent))]),_c('span',{staticClass:"contact_way"},[_vm._v(_vm._s(item.contactName))])])}),0):_c('div',{staticClass:"info contact_way_box"},[_c('span',{staticClass:"contact_way"},[_vm._v("- -")]),_c('span',{staticClass:"contact_way"},[_vm._v("- -")]),_c('span',{staticClass:"contact_way"},[_vm._v("- -")])])]),_c('el-form-item',{attrs:{"label":"备注"}},[_c('div',{staticClass:"info"},[_vm._v(_vm._s(_vm.waitData.remark || '--'))])]),_c('div',[_c('el-form-item',{attrs:{"label":"用户数"}},[_c('div',{staticClass:"info"},[_vm._v(_vm._s(_vm.waitData.userNo || '--'))])]),_c('el-form-item',{attrs:{"label":"QQ号"}},[_c('div',{staticClass:"info"},[_vm._v(_vm._s(_vm.waitData.qq || '--'))])]),_c('el-form-item',{attrs:{"label":"微信号"}},[_c('div',{staticClass:"info"},[_vm._v(_vm._s(_vm.waitData.weChat || '--'))])]),_c('el-form-item',{attrs:{"label":"联系邮箱"}},[_c('div',{staticClass:"info"},[_vm._v(_vm._s(_vm.waitData.email || '--'))])]),_c('el-form-item',{attrs:{"label":"域名备注"}},[_c('div',{staticClass:"info"},[_vm._v(_vm._s(_vm.waitData.companyDomain || '--'))])]),_c('el-form-item',{attrs:{"label":"联系方式备注"}},[_c('div',{staticClass:"info"},[_vm._v(_vm._s(_vm.waitData.contactWay || '--'))])])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }