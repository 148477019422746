<template>
    <div class="table-box">
        <el-table
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '600',
            }"
            @selection-change="handleSelectionChange"
        >
            <el-table-column type="selection" width="50" align="center">
            </el-table-column>
            <el-table-column label="编辑时间" show-overflow-tooltip width="93">
                <template slot-scope="scope">
                    {{
                        $tableDataHandle.createTime({
                            createTime: scope.row.updateTime,
                        })
                    }}
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="公司名称" show-overflow-tooltip width="239">
                <template slot-scope="scope">
                    <span>
                        <i
                            v-if="statusVal != '已转化'"
                            style="cursor: pointer"
                            @click="onStarMark(scope.row)"
                            :class="
                                scope.row.mark == 1
                                    ? 'iconfont icon-shoucang'
                                    : 'iconfont icon-star'
                            "
                        ></i>
                        <i
                            v-else
                            style="cursor: pointer"
                            :class="
                                scope.row.mark == 1
                                    ? 'iconfont icon-shoucang'
                                    : 'iconfont icon-star'
                            "
                        ></i>
                        <el-button
                            v-if="statusVal != '已转化'"
                            type="text"
                            style="color: #2370eb"
                            @click="onEdit(scope.row)"
                            >{{ scope.row.companyName }}</el-button
                        >
                        <span v-else>{{ scope.row.companyName }}</span>
                    </span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column :label="'  联系方式'" width="95">
                <template slot-scope="scope">
                    <el-tooltip class="item" effect="dark" placement="top">
                        <div
                            slot="content"
                            v-if="scope.row.libraryContactBOList.length > 0"
                        >
                            <p
                                v-for="item in scope.row.libraryContactBOList"
                                :key="item.id"
                            >
                                <span>{{
                                    $tableDataHandle.contactWay(item)
                                }}</span>
                                {{ item.contactContent }}&nbsp;&nbsp;
                                {{ item.contactName }}
                            </p>
                        </div>
                        <span
                            type="text"
                            v-if="scope.row.libraryContactBOList.length > 0"
                            style="overflow: hidden; white-space: nowrap"
                        >
                            <span
                                :class="
                                    $tableDataHandle.contactWayColor(
                                        scope.row.libraryContactBOList[0]
                                    ).icon
                                "
                                style="margin-right: 5px"
                            ></span>
                            <span>{{
                                scope.row.libraryContactBOList[0].contactContent
                            }}</span>
                            <!-- scope.row.libraryContactBOList[0]
                                        .contactWay == 1
                                        ? 'iconfont icon-dianhua'
                                        : scope.row.libraryContactBOList[0]
                                              .contactWay == 2
                                        ? 'iconfont icon-weixin'
                                        : scope.row.libraryContactBOList[0]
                                              .contactWay == 3
                                        ? 'iconfont icon-youxiang'
                                        : scope.row.libraryContactBOList[0]
                                              .contactWay == 4
                                        ? 'iconfont icon-QQ'
                                        : scope.row.libraryContactBOList[0]
                                              .contactWay == 5
                                        ? 'iconfont icon-qita'
                                        : scope.row.libraryContactBOList[0]
                                              .contactWay == 6
                                        ? 'iconfont icon-yuyuebaifang'
                                        : scope.row.libraryContactBOList[0]
                                              .contactWay == 7
                                        ? 'iconfont icon-qita1'
                                        : '' -->
                        </span>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="法人"
                show-overflow-tooltip
                :formatter="legalPersonFormatter"
                width="53"
            >
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="域名" show-overflow-tooltip width="175">
                <template slot="header">
                    <div>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;域名
                    </div>
                </template>
                <template slot-scope="scope">
                    <span
                        v-if="scope.row.libraryDomainBOList.length > 1"
                        class="left_toast"
                    >
                        <span style="color: #2370eb"
                            >{{ scope.row.index + 1 }}/</span
                        >
                        <span>{{ scope.row.libraryDomainBOList.length }} </span>
                    </span>
                    <span
                        style="padding-left: 30px"
                        v-if="scope.row.libraryDomainBOList.length > 0"
                    >
                        {{
                            scope.row.libraryDomainBOList[scope.row.index]
                                .domain
                        }}</span
                    >
                    <span v-else style="display: block; padding-left: 30px"
                        >- -</span
                    >
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="品牌" show-overflow-tooltip width="85">
                <template slot-scope="scope">
                    <span
                        v-if="
                            scope.row.libraryDomainBOList.length > 0 &&
                            scope.row.libraryDomainBOList[scope.row.index]
                                .enterpriseDomainDO
                        "
                    >
                        <el-tooltip
                            v-if="
                                $tableDataHandle.dominBrand(
                                    scope.row.libraryDomainBOList[
                                        scope.row.index
                                    ].enterpriseDomainDO.domainMX
                                ) == '其他邮箱'
                            "
                            class="item"
                            effect="dark"
                            :content="
                                scope.row.libraryDomainBOList[scope.row.index]
                                    .enterpriseDomainDO.domainMX
                            "
                            placement="top"
                        >
                            <i style="font-style: normal">
                                {{
                                    $tableDataHandle.dominBrand(
                                        scope.row.libraryDomainBOList[
                                            scope.row.index
                                        ].enterpriseDomainDO.domainMX
                                    )
                                }}
                            </i>
                        </el-tooltip>
                        <i style="font-style: normal" v-else>
                            {{
                                $tableDataHandle.dominBrand(
                                    scope.row.libraryDomainBOList[
                                        scope.row.index
                                    ].enterpriseDomainDO.domainMX
                                )
                            }}
                        </i>
                    </span>
                    <span v-else>- -</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="标签" width="105">
                <template slot-scope="scope">
                    <el-tooltip
                        class="item"
                        effect="dark"
                        placement="top-start"
                        v-if="scope.row.tagList && scope.row.tagList.length > 0"
                    >
                        <div slot="content">
                            <span v-if="scope.row.tagList.length > 0">
                                <span
                                    v-for="item in scope.row.tagList"
                                    :key="item.id"
                                    >{{ item.tagName }}&nbsp;&nbsp;</span
                                >
                            </span>
                        </div>
                        <span v-if="scope.row.tagList.length > 0">
                            <span
                                class="Times"
                                v-for="item in scope.row.tagList"
                                :key="item.id"
                                >{{ item.tagName }}</span
                            >
                        </span>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="备案人" show-overflow-tooltip width="53">
                <template slot-scope="scope">
                    <span
                        v-if="
                            scope.row.libraryDomainBOList.length > 0 &&
                            scope.row.libraryDomainBOList[scope.row.index]
                                .enterpriseDomainDO
                        "
                    >
                        {{
                            scope.row.libraryDomainBOList[scope.row.index]
                                .enterpriseDomainDO.icpOwner
                                ? scope.row.libraryDomainBOList[scope.row.index]
                                      .enterpriseDomainDO.icpOwner
                                : '- -'
                        }}
                    </span>
                    <span v-else>- -</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="域名到期时间"
                show-overflow-tooltip
                width="130"
            >
                <template slot-scope="scope">
                    <div class="tableBtn-box" style="display: flex">
                        <div
                            v-if="
                                scope.row.libraryDomainBOList.length > 0 &&
                                scope.row.libraryDomainBOList[scope.row.index]
                                    .enterpriseDomainDO &&
                                scope.row.libraryDomainBOList[scope.row.index]
                                    .enterpriseDomainDO.icpDomainRecordTime &&
                                scope.row.libraryDomainBOList[scope.row.index]
                                    .enterpriseDomainDO.whoisDueDate
                            "
                        >
                            <p style="overflow: hidden; white-space: nowrap">
                                <img src="@/assets/shi.png" alt="" />
                                {{
                                    scope.row.libraryDomainBOList[
                                        scope.row.index
                                    ].enterpriseDomainDO.icpDomainRecordTime.slice(
                                        0,
                                        10
                                    )
                                }}
                            </p>
                            <p>
                                <img src="@/assets/mo.png" alt="" />
                                {{
                                    scope.row.libraryDomainBOList[
                                        scope.row.index
                                    ].enterpriseDomainDO.whoisDueDate.slice(
                                        0,
                                        10
                                    )
                                }}
                            </p>
                        </div>

                        <span v-else style="flex: 1; padding-left: 17px"
                            >- -</span
                        >
                        <p
                            class="tableBtn"
                            v-if="
                                scope.row.libraryDomainBOList &&
                                scope.row.libraryDomainBOList.length > 1
                            "
                        >
                            <el-button
                                icon="el-icon-caret-top"
                                :disabled="scope.row.index == 0"
                                :style="{
                                    color:
                                        scope.row.index == 0
                                            ? '#c9c9c9'
                                            : '#2370eb',
                                }"
                                type="primary"
                                @click="del(scope.row.id)"
                            ></el-button>
                            <el-button
                                icon="el-icon-caret-bottom"
                                :disabled="
                                    scope.row.index >=
                                    scope.row.libraryDomainBOList.length - 1
                                "
                                :style="{
                                    color:
                                        scope.row.index >=
                                        scope.row.libraryDomainBOList.length - 1
                                            ? '#c9c9c9'
                                            : '#2370eb',
                                }"
                                type="primary"
                                @click="add(scope.row.id)"
                            ></el-button>
                        </p>
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="往来时间" show-overflow-tooltip width="93">
                <template slot-scope="scope">
                    <el-tooltip class="item" effect="dark" placement="bottom">
                        <span>{{ dealingsTimeFormatter(scope.row) }}</span>
                        <div
                            slot="content"
                            v-if="scope.row.libraryIntercourseBOList.length > 0"
                            class="dealings"
                        >
                            <p>
                                <!-- @mouseover="mouseover(scope.row.id)" -->
                                <span>往来时间：</span>
                                {{
                                    scope.row.libraryIntercourseBOList[0]
                                        .intercourseTime
                                }}
                            </p>
                            <p>
                                <span>跟进人：</span>
                                {{
                                    scope.row.libraryIntercourseBOList[0]
                                        .adminName
                                }}
                            </p>
                            <p>
                                <span>联系方式：</span>
                                {{
                                    $tableDataHandle.contactWay(
                                        scope.row.libraryIntercourseBOList[0]
                                    )
                                }}
                            </p>
                            <p>
                                <span> 往来内容：</span>
                                {{
                                    scope.row.libraryIntercourseBOList[0]
                                        .intercourseContent
                                }}
                            </p>
                            <p
                                v-for="(item, index) in scope.row
                                    .libraryIntercourseBOList[0].describeList"
                                :key="index"
                            >
                                <span> 往来说明：</span>
                                {{ item.describeContent }}
                            </p>
                        </div>
                        <div slot="content" v-else>
                            <p>暂无数据</p>
                        </div>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                prop="createAdminName"
                label="创建人"
                show-overflow-tooltip
                width="53"
                v-if="
                    userType == 8 ||
                    userType == 9 ||
                    userType == 10 ||
                    userType == 4
                "
            >
            </el-table-column>
            <el-table-column
                min-width="20"
                v-if="
                    userType == 8 ||
                    userType == 9 ||
                    userType == 10 ||
                    userType == 4
                "
            >
            </el-table-column>
            <el-table-column
                label="操作"
                fixed="right"
                show-overflow-tooltip
                v-if="statusVal != '已转化'"
                width="180"
            >
                <template slot-scope="scope">
                    <el-button
                        v-if="btnP.dbDealings && statusVal != '已转化'"
                        style="color: #2370eb"
                        type="text"
                        @click="onDealings(scope.row)"
                    >
                        往来
                    </el-button>
                    <el-button
                        v-if="btnP.dbTransl && statusVal != '已转化'"
                        style="color: #2370eb"
                        type="text"
                        @click="onSwitch(scope.row)"
                    >
                        创建客户
                    </el-button>
                    <el-dropdown trigger="click" v-if="statusVal != '已转化'">
                        <span class="el-dropdown-link">
                            <i
                                class="iconfont icon-gengduo"
                                style="
                                    color: #2370eb;
                                    font-weight: 600;
                                    margin-left: 10px;
                                "
                            ></i>
                        </span>
                        <el-dropdown-menu
                            slot="dropdown"
                            style="margin-left: 10px; text-align: center"
                        >
                            <el-dropdown-item>
                                <el-button
                                    v-if="btnP.addClue"
                                    style="color: #2370eb"
                                    type="text"
                                    @click="onClue(scope.row)"
                                >
                                    创建线索
                                </el-button>
                            </el-dropdown-item>

                            <el-dropdown-item>
                                <el-button
                                    v-if="btnP.dbDelete"
                                    style="color: #2370eb"
                                    type="text"
                                    @click="onDetele(scope.row)"
                                >
                                    删除
                                </el-button>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
    </div>
</template>
<script>
import { libraryList, dealingList } from '@/api/database/database';
export default {
    data() {
        return {
            tableData: [],
            btnP: {},
            isChoice: false,
            choiceArr: [],
            dealing: [],
            tableRowId: '',
            userType: sessionStorage.getItem('userType'),
            pageData: {},
            statusVal: '',
        };
    },
    methods: {
        handleSelectionChange(val) {
            this.choiceArr = [];
            this.choiceArr = val;
        },
        // 获取列表
        getData(
            userType,
            searchVal,
            pageNum,
            pageSize,
            btnP,
            isChoice,
            active
        ) {
            this.statusVal = searchVal.statusVal;
            // // console.log(userType, searchVal, pageNum, pageSize);
            let data = {
                param: {},
                pageNum: pageNum,
                pageSize: pageSize,
            };
            if (!btnP.depSearch) {
                if (
                    this.userType == 8 ||
                    this.userType == 9 ||
                    this.userType == 10
                ) {
                    data.param.departmentId =
                        sessionStorage.getItem('departmentId');
                } else if (
                    this.userType == 1 ||
                    this.userType == 2 ||
                    this.userType == 3 ||
                    this.userType == 4
                ) {
                    data.param.adminId = sessionStorage.getItem('adminId');
                }
            }
            if (searchVal.name) {
                data.param.name = searchVal.name;
            }
            if (searchVal.statusVal == '未联系') {
                data.param.status = 0;
            } else if (searchVal.statusVal == '已联系') {
                data.param.status = 1;
            } else if (searchVal.statusVal == '已转化') {
                data.param.status = 2;
            }
            if (searchVal.departmentId) {
                data.param.departmentId = searchVal.departmentId;
            }
            if (searchVal.followAdminId) {
                data.param.followAdminId = searchVal.followAdminId;
                if (
                    this.userType == 1 ||
                    this.userType == 2 ||
                    this.userType == 3 ||
                    this.userType == 4
                ) {
                    delete data.param.adminId;
                }
            }
            let labelArr = this.$searchLabel.state.labelList;
            if (labelArr && labelArr.length > 0) {
                data.param.tagList = labelArr;
            }
            if (active === 1) {
                data.param.mark = active;
            }
            if (active === 0) {
                data.param.mark = active;
            }
            this.pageData = data;
            libraryList(data).then((res) => {
                for (let i in res.data.list) {
                    res.data.list[i].index = 0;
                }
                this.tableData = res.data.list;
                this.$emit('totalNub', res.data.total);
                this.btnP = btnP;
                this.$nextTick(() => {
                    this.$refs.multipleTable.doLayout();
                });
            });
        },
        onStarMark(data) {
            this.$emit('onStarMark', data);
        },
        // 详情
        onDetails(data) {
            this.$emit('onDetails', data);
        },
        // 往来
        onDealings(data) {
            this.$emit('onDealings', data);
        },
        mouseover(id) {
            if (id == this.tableRowId) {
                return;
            }
            this.tableRowId = id;
            this.dealingList(id);
        },
        // 往来列表
        dealingList(id) {
            let data = {
                param: {
                    libraryId: id,
                },
            };
            dealingList(data).then((res) => {
                if (res.code == 200) {
                    this.dealing = res.data.list;
                }
            });
        },
        //添加客户
        onSwitch(data) {
            this.$emit('addCustomer', data);
        },
        // 创建线索
        onClue(data) {
            this.$emit('onClue', data);
        },
        // 删除
        onDetele(data) {
            this.$emit('delData', data);
        },
        // 联系方式
        contactBOList(row) {
            // console.log(row);
            for (let i in row.libraryContactBOList) {
                let data =
                    row.libraryContactBOList[i].contactWay == 1
                        ? '电话'
                        : row.libraryContactBOList[i].contactWay == 2
                        ? '微信'
                        : row.libraryContactBOList[i].contactWay == 3
                        ? '邮箱'
                        : row.libraryContactBOList[i].contactWay == 4
                        ? 'QQ'
                        : row.libraryContactBOList[i].contactWay == 5
                        ? '短信'
                        : row.libraryContactBOList[i].contactWay == 6
                        ? '拜访'
                        : row.libraryContactBOList[i].contactWay == 7
                        ? '其他'
                        : '';
                return (
                    data +
                    ' ' +
                    row.libraryContactBOList[i].contactContent +
                    ' ' +
                    row.libraryContactBOList[i].contactName
                );
            }
            return '- -';
        },
        // 编辑
        onEdit(data) {
            // console.log(data);
            this.$emit('onEdit', data);
            this.$formDetails.commit('detailsData', JSON.stringify(data));
            sessionStorage.setItem('pageData', JSON.stringify(this.pageData));
        },
        // 法人
        legalPersonFormatter(row) {
            // // console.log(row);
            if (row.enterpriseNameDO) {
                if (row.enterpriseNameDO.legalPerson) {
                    return row.enterpriseNameDO.legalPerson;
                } else {
                    return '- -';
                }
            }
            return '- -';
        },
        // 往来时间
        dealingsTimeFormatter(row) {
            if (
                row.libraryIntercourseBOList &&
                row.libraryIntercourseBOList.length > 0
            ) {
                if (row.libraryIntercourseBOList[0].intercourseTime) {
                    return row.libraryIntercourseBOList[0].intercourseTime.slice(
                        0,
                        10
                    );
                }
            }
            return '- -';
        },
        add(id) {
            let index = this.tableData.findIndex((item) => item.id == id);
            this.tableData[index].index++;
            if (
                this.tableData[index].index >=
                this.tableData[index].libraryDomainBOList.length - 1
            ) {
                this.tableData[index].index =
                    this.tableData[index].libraryDomainBOList.length - 1;
            }
        },
        del(id) {
            let index = this.tableData.findIndex((item) => item.id == id);
            this.tableData[index].index--;
            // // console.log(this.tableData[index].index);
            if (this.tableData[index].index <= 0) {
                this.tableData[index].index = 0;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.table-box {
    width: 100%;
    height: 100%;
    .icon-dianhua {
        color: #2370eb;
    }
    .icon-weixin {
        color: #50b674;
    }
    .icon-QQ {
        color: #2370eb;
    }
    .icon-youxiang {
        color: #feb516;
    }
    .icon-qita {
        color: #13ab16;
        font-size: 22px;
        margin-left: -2px;
    }
    .icon-yuyuebaifang {
        color: #f15641;
    }
    .icon-qita1 {
        color: #af90ff;
    }
    .icon-duanxinxiaoxixinxi {
        color: #13ab16;
    }
    .icon-diannao {
        color: #87b1e9;
    }
    .icon-youxiang1 {
        color: #feb516;
    }
    .tableBtn-box {
        display: flex;
        align-items: center;
        justify-items: center;
        // position: relative;
        height: 40px;
    }

    .tableBtn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        margin-left: 10px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 00px;
        height: 40px;
        button {
            width: 20px;
            height: 20px;
            margin: 0;
            border-radius: 0;
            background: transparent;
            border: none;
            position: relative;
        }
        button:nth-child(1) {
            line-height: 2;
        }
        button:nth-child(2) {
            /deep/ .el-icon-caret-bottom {
                position: absolute;
                top: -3px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
    /deep/ .disableheadselection > .cell .el-checkbox__inner {
        display: none;
    }
    /deep/ .el-table tr td {
        padding: 5px 0;
    }
    /deep/ .el-table tr td:nth-child(9) {
        padding-right: 30px;
        position: relative;
    }
    .icon-shoucang {
        color: #feb516;
        margin-right: 5px;
    }
    .icon-star {
        margin-right: 5px;
    }
}
.dealings {
    p {
        margin-bottom: 5px;
        span {
            display: inline-block;
            width: 60px;
        }
    }
}
.el-table th:nth-child(4) {
    padding-left: 10px;
}
/deep/.el-table tbody td {
    border-right: none !important;
}
/deep/ .el-table th:nth-child(18) .cell {
    padding-left: 25px;
}
/deep/ td:nth-child(6) .cell {
    padding-left: 0;
}
/deep/ th:nth-child(6) .cell {
    padding-left: 20px;
}
/deep/ th:nth-child(4) .cell {
    padding-left: 15px;
}
.icon-dianhua,
.icon-weixin,
.icon-QQ,
.icon-youxiang,
.icon-youxiang1,
.icon-duanxinxiaoxixinxi,
.icon-qita,
.icon-diannao,
.icon-yuyuebaifang,
.icon-qita1 {
    position: static;
    left: -10px !important;
}
.icon-shoucang:before {
    color: #feb516;
    position: absolute;
    left: -4px;
}

.icon-star:before {
    color: #c9c9c9;
    position: absolute;
    left: -4px;
}
.left_toast {
    position: absolute;
    top: 18px;
    left: 10px;
    font-size: 12px;
    line-height: 1.2;
}
/deep/ .el-table .cell {
    text-overflow: initial;
    padding-right: 0;
}
.Times {
    height: 24px;
    background: #fff2ed;
    border-radius: 2px;
    border: 1px solid #ff7a44;
    display: inline-block;
    padding: 2px 5px;
    margin-right: 16px;
    font-size: 12px;
    font-weight: 400;
    color: #ff7a45;
}
</style>
