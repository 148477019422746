<template>
    <div class="add_cutomer_box">
        <div class="add_customer">
            <div>
                <div class="left_form" v-for="(item, index) in formDatas">
                    <span class="form_Title">线索{{ index + 1 }}</span>
                    <WaitData :formData="item"></WaitData>
                    <p class="business_info">
                        <span>工商信息</span>
                        <span class="hide_btn" @click="changeStatus(index)"
                            >{{ item.tag ? '收起' : '展开' }}
                            <i
                                :class="
                                    item.tag
                                        ? 'el-icon-arrow-up'
                                        : 'el-icon-arrow-down'
                                "
                            ></i
                        ></span>
                    </p>
                    <BusinessInfo
                        :businessInfo="item.enterpriseNameDO"
                        v-show="item.tag"
                    ></BusinessInfo>
                </div>
            </div>
            <div class="right_form">
                <MergerForm ref="mergerForm" :formData="formData" />
            </div>
        </div>
        <div class="footer_btn">
            <el-button
                size="mini"
                @click="onClose()"
                round
                style="padding: 5px 20px"
                >取消</el-button
            >
            <el-button
                type="primary"
                size="mini"
                round
                style="
                    background: #2370eb;
                    border-color: #2370eb;
                    padding: 5px 20px;
                "
                @click="onSubmit"
                >保存</el-button
            >
        </div>
    </div>
</template>

<script>
import { libraryAdd, setStatus, libraryGet } from '@/api/database/database';
import WaitData from '../addCustomer/components/waitData.vue';
import BusinessInfo from '../addCustomer/components/businessInfo.vue';
import MergerForm from './mergerForm.vue';
export default {
    name: '',
    props: {},
    data() {
        return {
            formDatas: [],
            formData: {},
        };
    },
    components: {
        WaitData,
        BusinessInfo,
        MergerForm,
    },
    methods: {
        getData(arr) {
            this.formDatas = arr;
            this.formData = arr;
        },
        changeStatus(index) {
            this.formDatas[index].tag = !this.formDatas[index].tag;
            let data = this.formDatas;
            this.formDatas = [];
            this.formDatas = data;
        },

        onClose() {
            this.$emit('close');
        },
        onSubmit() {
            this.formDatas.forEach((item) => {
                this.setStatus(item.id);
            });
            this.addData();
        },
        // 添加资料
        addData() {
            let waitData = this.$refs.mergerForm.$data.waitData;
            let domainList = waitData.libraryDomainBOList.map((item) => {
                return {
                    domain: item.domain,
                };
            });
            if (!waitData.companyName) {
                return this.$message.error('请输入公司名称');
            }
            //   for (let i in domainList) {
            //     if (!domainList[i].domain) {
            //       return this.$message.error("请输入域名");
            //     }
            //   }
            //   for (let i in waitData.libraryContactBOList) {
            //     if (
            //       !waitData.libraryContactBOList[i].contactWay &&
            //       !waitData.libraryContactBOList[i].contactName &&
            //       !waitData.libraryContactBOList[i].contactContent
            //     ) {
            //       return this.$message.error("请输入联系方式");
            //     }
            //   }
            let data = {
                param: {
                    libraryDO: {
                        companyName: waitData.companyName,
                        userNo: waitData.userNo,
                        qq: waitData.qq,
                        weChat: waitData.weChat,
                        email: waitData.email,
                        remark: waitData.remark,
                        companyDomain: waitData.companyDomain,
                        contactWay: waitData.contactWay,
                    },
                    libraryDomainDOList: waitData.libraryDomainBOList.map(
                        (item) => {
                            return {
                                domain: item.domain,
                            };
                        }
                    ),
                    libraryContactDOList: waitData.libraryContactBOList,
                },
            };
            libraryAdd(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('添加成功');
                    this.onClose();
                }
            });
        },
        // 删除资料
        setStatus(id) {
            let data = {
                param: {
                    id: id,
                    status: 3,
                },
            };
            setStatus(data).then((res) => {});
        },
    },
};
</script>

<style scoped>
.add_cutomer_box {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
}
.add_customer {
    background-color: #f0f2f4;
    display: flex;
    width: 100%;
    flex: 1;
    justify-content: space-between;
    overflow: hidden;
}
.add_customer > div {
    width: 49.5%;
    background-color: #fff;

    box-sizing: border-box;
    overflow-y: auto;
}
.add_customer > div p {
    font-size: 14px;
}
.wait_data span {
    background: rgba(35, 112, 235, 0.07);
    border-radius: 1px;
    border: 1px solid #2370eb;
    display: inline-block;
    color: #2370eb;
    line-height: 1.4;
    padding: 0 10px;
}
.customer_form span {
    background: rgba(255, 168, 2, 0.07);
    border-radius: 1px;
    border: 1px solid #ffa802;
    display: inline-block;
    color: #ffa802;
    line-height: 1.4;
    padding: 0 10px;
}
.add_customer .business_info {
    margin-top: 25px;
    position: relative;
    margin-bottom: 25px;
    padding-left: 30px;
    padding-right: 30px;
}
.add_customer .business_info span:before {
    content: '';
    position: absolute;
    left: 20px;
    top: 0;
    height: 100%;
    width: 3px;
    background: #ffa802;
}
.add_customer .business_info .hide_btn {
    float: right;
    border-radius: 1px;
    border: 1px solid #2370eb;
    line-height: 1.5;
    font-size: 14px;
    padding: 0 8px;
    /* display: inline-block; */
    color: #2370eb;
    cursor: pointer;
}
.add_cutomer_box .footer_btn {
    height: 50px;
    line-height: 50px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
    padding-right: 30px;
}
.footer_btn button:nth-child(2) {
    margin: 0 10px 0 20px;
}
.left_form {
    margin-bottom: 50px;
}
.form_Title {
    display: inline-block;
    padding: 2px 5px;
    font-size: 9px;
    margin-left: 30px;
    margin-top: 15px;
    color: #2370eb;
    border: 1px solid #2370eb;
}
</style>
