<template>
    <div class="waitData">
        <el-form label-width="80px" label-position="left" size="mini">
            <el-form-item label="公司名称" required>
                <el-input
                    v-model="waitData.companyName"
                    placeholder="请输入公司名称"
                    style="width:270px"
                    size="small"
                    :disabled="disabled"
                ></el-input>
            </el-form-item>
            <template v-for="(item, index) in waitData.libraryDomainBOList">
                <div :key="item.id">
                    <el-form-item label="域名" required>
                        <el-input
                            :disabled="disabled"
                            v-model="item.domain"
                            placeholder="域名"
                            style="width:270px"
                            size="small"
                        ></el-input>
                        <el-button
                            v-if="index == 0 && !disabled"
                            @click="addDomain"
                            type="primary"
                            class="el-icon-plus"
                            circle
                            size="mini"
                        ></el-button>
                        <el-button
                            v-if="index > 0 && !disabled"
                            @click.prevent="removeDomain(index)"
                            class="el-icon-minus"
                            type="primary"
                            circle
                            size="mini"
                        ></el-button>
                    </el-form-item>
                    <template>
                        <div class="domain_detail" v-show="item.tag">
                            <div
                                class="domain_info"
                                v-if="item.enterpriseDomainDO"
                            >
                                <el-form-item label="原邮箱品牌">
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        v-if="
                                            $tableDataHandle.dominBrand(
                                                item.enterpriseDomainDO.domainMX
                                            ) != '无'
                                        "
                                        :content="
                                            item.enterpriseDomainDO.domainMX
                                        "
                                        placement="top"
                                    >
                                        <div class="info">
                                            {{
                                                $tableDataHandle.dominBrand(
                                                    item.enterpriseDomainDO
                                                        .domainMX
                                                )
                                            }}
                                        </div>
                                    </el-tooltip>
                                    <div v-else class="info">
                                        {{
                                            $tableDataHandle.dominBrand(
                                                item.enterpriseDomainDO.domainMX
                                            )
                                        }}
                                    </div>
                                </el-form-item>
                                <el-form-item label="备案人">
                                    <div class="info">
                                        {{
                                            item.enterpriseDomainDO.icpOwner ||
                                                '--'
                                        }}
                                    </div>
                                </el-form-item>
                                <el-form-item label="网站名">
                                    <div class="info">
                                        {{
                                            item.enterpriseDomainDO
                                                .icpRecordName || '--'
                                        }}
                                    </div>
                                </el-form-item>
                                <el-form-item label="whois">
                                    <div class="info">
                                        {{
                                            item.enterpriseDomainDO
                                                .icpDomainRecordTime || '--'
                                        }}
                                        <i class="el-icon-right"></i>
                                        {{
                                            item.enterpriseDomainDO.whoisDueDate
                                                ? item.enterpriseDomainDO.whoisDueDate.split(
                                                      ' '
                                                  )[0]
                                                : '--'
                                        }}
                                    </div>
                                </el-form-item>
                                <el-form-item label="域名注册平台">
                                    <div class="info">
                                        {{
                                            item.enterpriseDomainDO
                                                .whoisRegistrant || '- -'
                                        }}
                                    </div>
                                </el-form-item>
                                <el-form-item style="margin-bottom:0">
                                    <span
                                        class="mini_btn"
                                        @click="hideDetail(index)"
                                        >收起 <i class="el-icon-arrow-up"></i>
                                    </span>
                                </el-form-item>
                            </div>
                            <div class="domain_info" v-else>
                                <el-form-item label="原邮箱品牌">
                                    <div class="info">
                                        - -
                                    </div>
                                </el-form-item>
                                <el-form-item label="备案人">
                                    <div class="info">
                                        - -
                                    </div>
                                </el-form-item>
                                <el-form-item label="网站名">
                                    <div class="info">
                                        - -
                                    </div>
                                </el-form-item>
                                <el-form-item label="whois">
                                    <div class="info">
                                        - -
                                        <i class="el-icon-right"></i>
                                        - -
                                    </div>
                                </el-form-item>
                                <el-form-item label="域名注册平台">
                                    <div class="info">
                                        - -
                                    </div>
                                </el-form-item>
                                <el-form-item style="margin-bottom:0">
                                    <span
                                        class="mini_btn"
                                        @click="hideDetail(index)"
                                        >收起 <i class="el-icon-arrow-up"></i>
                                    </span>
                                </el-form-item>
                            </div>
                        </div>
                        <el-form-item
                            style="padding-left:10px"
                            v-show="!item.tag"
                        >
                            <span class="mini_btn" @click="showDetail(index)"
                                >详细信息 <i class="el-icon-arrow-down"></i>
                            </span>
                        </el-form-item>
                    </template>
                </div>
            </template>
            <el-form-item label="联系方式" v-if="!disabled">
                <div
                    class="info contact_way_ox"
                    v-for="(item, index) in waitData.libraryContactBOList"
                    :key="item.id"
                >
                    <el-button
                        v-if="index > 0 && !disabled"
                        type="primary"
                        @click="toTop(index)"
                        class="el-icon-top ordinary"
                        circle
                        size="mini"
                    ></el-button>
                    <el-select
                        v-model="item.contactWay"
                        size="small"
                        style="width:70px"
                        clearable
                        :disabled="disabled"
                        placeholder="方式"
                    >
                        <el-option label="电话" :value="1"></el-option>
                        <el-option label="微信" :value="2"></el-option>
                        <el-option label="邮箱" :value="3"></el-option>
                        <el-option label="QQ" :value="4"></el-option>
                        <el-option label="短信" :value="5"></el-option>
                        <el-option label="拜访" :value="6"></el-option>
                        <!-- <el-option label="官网表单" :value="8"></el-option> -->
                        <el-option label="座机" :value="9"></el-option>
                        <el-option label="其他" :value="7"></el-option>
                    </el-select>
                    <el-input
                        :disabled="disabled"
                        style="margin-left:5px;width:130px"
                        v-model="item.contactContent"
                        placeholder="请输入"
                        size="small"
                    ></el-input>
                    <el-input
                        :disabled="disabled"
                        style="margin-left:5px;width:60px;"
                        class="a"
                        v-model="item.contactName"
                        placeholder="联系人"
                        size="small"
                    ></el-input>
                    <el-button
                        v-if="index == 0 && !disabled"
                        @click="addContact"
                        type="primary"
                        class="el-icon-plus"
                        circle
                        size="mini"
                    ></el-button>
                    <el-button
                        v-if="index > 0 && !disabled"
                        @click.prevent="removeContact(item)"
                        class="el-icon-minus"
                        type="primary"
                        circle
                        size="mini"
                    ></el-button>
                </div>
            </el-form-item>
            <el-form-item label="联系方式" v-if="disabled">
                <div
                    class="info contact_way_box"
                    v-for="item in waitData.libraryContactBOList"
                    :key="item.id"
                >
                    <span class="contact_way">{{
                        $tableDataHandle.contactWay(item)
                    }}</span>
                    <span class="contact_way">{{
                        item.contactContent || '- -'
                    }}</span>
                    <span class="contact_way">{{
                        item.contactName || '- -'
                    }}</span>
                </div>
            </el-form-item>
            <el-form-item label="备注">
                <el-input
                    type="textarea"
                    rows="5"
                    style="width:270px"
                    v-model="waitData.remark"
                    :disabled="disabled"
                    placeholder="请输入"
                    size="small"
                ></el-input>
            </el-form-item>
            <div v-show="tag">
                <el-form-item label="用户数">
                    <el-input
                        style="width:270px"
                        v-model.number="waitData.userNo"
                        placeholder="请输入"
                        :disabled="disabled"
                        size="small"
                    ></el-input>
                </el-form-item>
                <el-form-item label="QQ号">
                    <el-input
                        style="width:270px"
                        v-model="waitData.qq"
                        :disabled="disabled"
                        placeholder="请输入"
                        size="small"
                    ></el-input>
                </el-form-item>
                <el-form-item label="微信号">
                    <el-input
                        style="width:270px"
                        v-model="waitData.weChat"
                        :disabled="disabled"
                        placeholder="请输入"
                        size="small"
                    ></el-input>
                </el-form-item>
                <el-form-item label="联系邮箱">
                    <el-input
                        style="width:270px"
                        v-model="waitData.email"
                        :disabled="disabled"
                        placeholder="请输入"
                        size="small"
                    ></el-input>
                </el-form-item>
                <el-form-item label="域名备注">
                    <el-input
                        style="width:270px"
                        v-model="waitData.companyDomain"
                        :disabled="disabled"
                        placeholder="请输入"
                        size="small"
                    ></el-input>
                </el-form-item>
                <el-form-item label="联系方式备注">
                    <el-input
                        style="width:270px"
                        type="textarea"
                        rows="5"
                        v-model="waitData.contactWay"
                        :disabled="disabled"
                        placeholder="请输入"
                        size="small"
                    ></el-input>
                </el-form-item>
            </div>
            <el-form-item>
                <span class="hide_btn" @click="changeFormStatus"
                    >{{ tag ? '收起' : '展开' }}
                    <i
                        :class="tag ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
                    ></i
                ></span>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    name: '',
    props: ['formData', 'disabled', 'wash'],
    data() {
        return {
            waitData: {
                libraryDomainBOList: [],
                libraryContactBOList: [],
            },
            tag: true,
            // disabled: false,
        };
    },
    watch: {
        formData: {
            deep: true,
            handler(val, oldVal) {
                let data = JSON.stringify(val);
                this.waitData = Object.assign({}, JSON.parse(data)[0]);
                this.waitData.contactWay = '';
                JSON.parse(data).forEach((item, index) => {
                    if (index > 0) {
                        this.waitData.libraryContactBOList = this.MergeArray(
                            this.waitData.libraryContactBOList,
                            item.libraryContactBOList
                        );
                        this.waitData.libraryDomainBOList = this.MergeArray(
                            this.waitData.libraryDomainBOList,
                            item.libraryDomainBOList
                        );
                        // this.waitData.libraryContactBOList = this.waitData.libraryContactBOList.concat(
                        //   item.libraryContactBOList
                        // );
                        // this.waitData.libraryDomainBOList = this.waitData.libraryDomainBOList.concat(
                        //   item.libraryDomainBOList
                        // );
                    }
                    if (item.contactWay) {
                        this.waitData.contactWay += item.contactWay + ';';
                    }
                });

                if (
                    this.waitData.libraryContactBOList &&
                    this.waitData.libraryContactBOList.length == 0
                ) {
                    this.waitData.libraryContactBOList = [{}];
                }
                if (
                    this.waitData.libraryDomainBOList &&
                    this.waitData.libraryDomainBOList.length == 0
                ) {
                    this.waitData.libraryDomainBOList = [{}];
                }
                this.waitData.libraryDomainBOList
                    ? this.waitData.libraryDomainBOList.forEach((item) => {
                          item.tag = false;
                      })
                    : '';
            },
        },
    },
    components: {},

    methods: {
        MergeArray(arr1, arr2) {
            // console.log(arr1, arr2);
            if (arr1.length == 0 && arr2.length == 0) {
                return [{}];
            }
            var _arr = new Array();
            for (var i = 0; i < arr1.length; i++) {
                _arr.push(arr1[i]);
            }
            for (var i = 0; i < arr2.length; i++) {
                var flag = true;
                for (var j = 0; j < arr1.length; j++) {
                    if (arr2[i] == arr1[j]) {
                        flag = false;
                        break;
                    }
                }
                if (flag) {
                    _arr.push(arr2[i]);
                }
            }
            return _arr;
        },
        // 收起详细信息
        hideDetail(index) {
            this.waitData.libraryDomainBOList[index].tag = false;
            let data = this.waitData;
            this.waitData = [];
            this.waitData = data;
        },
        // 显示详细信息
        showDetail(index) {
            this.waitData.libraryDomainBOList[index].tag = true;
            // console.log(this.waitData.libraryDomainBOList);
            let data = this.waitData;
            this.waitData = [];
            this.waitData = data;
        },
        changeFormStatus() {
            this.tag = !this.tag;
        },
        // 新增联系方式
        addContact() {
            this.waitData.libraryContactBOList.push({
                contactWay: 1,
                contactContent: '',
                contactName: '',
            });
            // this.$emit("changeData", this.waitData);
        },
        // 删除联系方式
        removeContact(item) {
            var index = this.waitData.libraryContactBOList.indexOf(item);
            if (index !== -1) {
                this.waitData.libraryContactBOList.splice(index, 1);
            }
        },
        //添加域名
        addDomain() {
            this.waitData.libraryDomainBOList.push({
                domain: '',
            });
        },
        // 删除域名
        removeDomain(index) {
            this.waitData.libraryDomainBOList.splice(index, 1);
        },
        // 置顶
        toTop(index) {
            let arr = this.waitData.libraryContactBOList.splice(index, 1);
            this.waitData.libraryContactBOList.unshift(arr[0]);
        },
    },
};
</script>

<style scoped>
.waitData {
    background-color: #fff;
    width: 100%;
    margin-top: 10px;
}
.waitData .info {
    background-color: #f9f9f9;
    padding-left: 10px;
    box-sizing: border-box;
    height: 100%;
}
.waitData .domain_detail {
    margin-bottom: 10px;
}
.waitData .domain_info {
    background: rgba(255, 168, 2, 0.05);
    border-radius: 8px;
    border: 1px solid #ffca66;
    padding: 10px;
}
.waitData .domain_info .info {
    background: #fdf7ec;
    border-radius: 2px;
    border: 1px solid #f0f0f0;
}
.waitData .mini_btn {
    color: #ffa802;
    cursor: pointer;
    font-size: 12px;
    user-select: none;
}
.waitData .hide_btn {
    border-radius: 1px;
    border: 1px solid #2370eb;
    line-height: 1.5;
    font-size: 14px;
    padding: 0 8px;
    display: inline-block;
    color: #2370eb;
    cursor: pointer;
}
.waitData .contact_way {
    background-color: #f9f9f9;
    padding: 0 5px;
    box-sizing: border-box;
    white-space: nowrap;
    display: flex;
    align-items: center;
    border-radius: 4px;
    /* text-overflow: ellipsis;
  overflow: hidden; */
}
.waitData .contact_way_box {
    background-color: #fff;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding-left: 0;
    margin-bottom: 10px;
    height: 32px;
    line-height: 32px;
    position: relative;
    width: 270px;
}
.waitData .contact_way_ox {
    background-color: #fff;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding-left: 0;
    margin-bottom: 10px;
    position: relative;
}
.waitData .contact_way:nth-child(2) {
    flex: 1;
    margin: 0 5px;
    word-break: break-all;
    white-space: normal;
}
.el-button--mini.is-circle {
    padding: 2px;
    background: #fff;
    color: #2370eb;
    font-weight: 600;
    width: 20px;
    height: 20px;
    border: 2px solid #2370eb;
    margin-left: 17px;
}
.el-button--mini.is-circle.ordinary {
    color: #999999;
    border-color: #999999;
    margin-left: 0;
    width: 20px;
    height: 20px;
    position: absolute;
    left: -30px;
    top: 50%;
    transform: translateY(-50%);
}
.el-button--mini.is-circle.ordinary:hover {
    background: #fff;
    color: #2370eb;
    border: 2px solid #2370eb;
}
/deep/ .el-textarea__inner {
    resize: none;
}
.a /deep/ input {
    padding: 0 5px;
}
</style>
