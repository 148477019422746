<template>
  <div class="addForm">
    <el-form
      ref="form"
      style="flex:1;margin-top:20px;margin-left: 40px;margin-bottom:80px"
      label-position="left"
      :model="form"
      label-width="80px"
    >
      <el-form-item label="公司名称" required>
        <el-input
          v-model="companyName"
          size="small"
          placeholder="请输入公司名称"
        ></el-input>
      </el-form-item>
      <div v-for="(item, index) in domainList">
        <el-form-item label="域名" :key="item.key" required>
          <el-input
            v-model="item.domain"
            placeholder="请输入域名"
            size="small"
          ></el-input>
          <el-button
            v-if="index == 0"
            @click="addDomain()"
            type="primary"
            class="el-icon-plus"
            circle
            size="mini"
          ></el-button>
          <el-button
            v-if="index > 0"
            @click.prevent="removeDomain(item)"
            class="el-icon-minus"
            type="primary"
            circle
            size="mini"
          ></el-button>
        </el-form-item>
      </div>
      <div v-for="(item, index) in contactList">
        <el-form-item label="联系方式" :key="item.key" required>
          <el-select
            v-model="item.contactWay"
            size="small"
            style="width:80px"
            clearable
            placeholder="方式"
          >
            <el-option label="电话" :value="1"></el-option>
            <el-option label="微信" :value="2"></el-option>
            <el-option label="邮箱" :value="3"></el-option>
            <el-option label="QQ" :value="4"></el-option>
            <el-option label="短信" :value="5"></el-option>
            <el-option label="拜访" :value="6"></el-option>
            <!-- <el-option label="官网表单" :value="8"></el-option> -->
            <el-option label="座机" :value="9"></el-option>
            <el-option label="其他" :value="7"></el-option>
          </el-select>

          <el-input
            style="margin-left:5px;width:120px"
            v-model="item.contactContent"
            placeholder="请输入"
            size="small"
          ></el-input>
          <el-input
            style="margin-left:5px;width:80px"
            v-model="item.contactName"
            placeholder="联系人"
            size="small"
          ></el-input>
          <el-button
            v-if="index == 0"
            @click="addContact"
            type="primary"
            class="el-icon-plus"
            circle
            size="mini"
          ></el-button>
          <el-button
            v-if="index > 0"
            @click.prevent="removeContact(item)"
            class="el-icon-minus"
            type="primary"
            circle
            size="mini"
          ></el-button>
        </el-form-item>
      </div>
      <el-form-item label="备注">
        <el-input
          style="margin-left:0px;width:290px"
          v-model="remark"
          rows="6"
          type="textarea"
          size="small"
          placeholder="请输入备注"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="bottom-but">
      <el-button
        round
        type="primary"
        size="mini"
        style="background:none;border-color:#D5D6DF;color:#666666;margin-left: auto;margin-left:260px"
        @click="onClose"
        >取消</el-button
      >
      <el-button
        round
        type="primary"
        style="background:#2370EB;border-color:#2370EB;color:#fff;margin-right:30px"
        size="mini"
        @click="onSubmit"
        >确定</el-button
      >
    </div>
  </div>
</template>
<script>
import { libraryAdd } from "@/api/database/database";
export default {
  data() {
    return {
      form: {},
      companyName: "",
      domainList: [
        {
          domain: "",
        },
      ],
      contactList: [
        {
          contactWay: 1,
          contactName: "",
          contactContent: "",
        },
      ],
      remark: "",
    };
  },
  methods: {
    // 新增关联域名
    addDomain() {
      this.domainList.push({
        domain: "",
      });
    },
    // 删除关联域名
    removeDomain(item) {
      var index = this.domainList.indexOf(item);
      if (index !== -1) {
        this.domainList.splice(index, 1);
      }
    },
    // 新增联系方式
    addContact() {
      this.contactList.push({});
    },
    // 删除联系方式
    removeContact(item) {
      var index = this.contactList.indexOf(item);
      if (index !== -1) {
        this.contactList.splice(index, 1);
      }
    },
    // 提交
    onSubmit() {
      if (!this.companyName) {
        return this.$message.error("请输入公司名称");
      }
      for (let i in this.domainList) {
        if (!this.domainList[i].domain) {
          return this.$message.error("请输入域名");
        }
      }
      for (let i in this.contactList) {
        if (
          this.contactList[i].contactWay == "" ||
          this.contactList[i].contactContent == ""
        ) {
          return this.$message.error("请输入联系方式");
        }
      }
      let data = {
        param: {
          libraryDO: {
            companyName: this.companyName,
            remark: this.remark,
          },
          libraryDomainDOList: this.domainList,
          libraryContactDOList: this.contactList,
        },
      };
      libraryAdd(data).then((res) => {
        if (res.code == 200) {
          this.$message.success("添加成功");
          this.onClose();
        }
      });
    },
    // 关闭
    onClose() {
      this.$emit("close");
      this.companyName = "";
      this.remark = "";
      this.domainList = [{}];
      this.contactList = [
        {
          contactWay: 1,
          contactName: "",
          contactContent: "",
        },
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
  /* WebKit browsers 适配谷歌 */
  font-size: 12px !important;
}
.addForm {
  width: 100%;
  height: 100%;
  .el-input {
    width: 290px;
  }
  /deep/ .el-textarea__inner {
    resize: none;
  }
  .el-button--mini.is-circle {
    padding: 2px;
    background: #fff;
    color: #2370eb;
    font-weight: 600;
    border: 2px solid #2370eb;
    margin-left: 17px;
  }
  .bottom-but {
    width: 100%;
    line-height: 48px;
    background: #fff;
    border-top: 1px solid #dee0e3;
    z-index: 1000;
    bottom: 0;
    position: fixed;
    button {
      width: 90px;
      z-index: 1000;
    }
  }
}
</style>
