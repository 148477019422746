<template>
    <div>
        <div style="padding:20px 50px 150px 50px">
            <div style="padding:20px;color:#333333">
                已选中{{
                    data.length
                }}条资料，即将开始未清洗资料的清洗，清洗可能需要一些时间，<br />完成后将以消息推送的方式通知你
            </div>
        </div>
        <div class="but">
            <el-button
                round
                type="primary"
                size="mini"
                style="background:none;border-color:#D5D6DF;color:#666666;margin-left: auto;"
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                style="background:#2370EB;border-color:#2370EB;color:#fff;margin-right:30px"
                size="mini"
                @click="onSubmit"
                >确定</el-button
            >
        </div>
    </div>
</template>
<script>
import { libraryClean } from '@/api/database/database';
export default {
    data() {
        return {
            data: [],
        };
    },
    methods: {
        getData(data) {
            this.data = data;
        },
        onSubmit() {
            let data = {
                param: [],
            };
            for (let i in this.data) {
                data.param.push({ id: this.data[i].id });
            }
            // console.log(data);
            libraryClean(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('清洗成功');
                    this.$emit('close');
                }
            });
        },
        onClose() {
            this.$emit('close');
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
        width: 72px;
    }
}
</style>
